import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Послуги | Clean&Co Services
			</title>
			<meta name={"description"} content={"Підвищення рівня вашого досвіду в прибиранні"} />
			<meta property={"og:title"} content={"Послуги | Clean&Co Services"} />
			<meta property={"og:description"} content={"Підвищення рівня вашого досвіду в прибиранні"} />
			<meta property={"og:image"} content={"https://quantumixfix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quantumixfix.com/img/5526086.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quantumixfix.com/img/5526086.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quantumixfix.com/img/5526086.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quantumixfix.com/img/5526086.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quantumixfix.com/img/5526086.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quantumixfix.com/img/5526086.png"} />
			<meta name={"msapplication-TileColor"} content={"https://quantumixfix.com/img/5526086.png"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://quantumixfix.com/img/7.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h1"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Послуги
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Спробуйте наші послуги
				</Text>
				<Text font="--base">
				У Clean&Co Services ми пишаємося тим, що надаємо виняткові рішення з прибирання, розроблені з урахуванням унікальних потреб наших клієнтів. Наш комплексний спектр послуг гарантує, що кожен куточок вашої оселі виблискуватиме чистотою та свіжістю. Незалежно від того, чи потрібне вам одноразове глибоке прибирання або регулярне обслуговування, наша команда професіоналів прагне перевершити ваші очікування.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--dark"
					font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Наша професійна команда
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Наша висококваліфікована та досвідчена команда є основою нашого успіху. Кожен член нашої клінінгової команди ретельно відібраний і ретельно навчений, щоб надавати першокласні послуги з прибирання з прискіпливою увагою до деталей. Ми віримо, що відмінний клінінг починається з відмінних людей, і наша команда прагне зробити ваш будинок чистішим і здоровішим місцем для життя.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Ми пропонуємо широкий спектр клінінгових послуг, розроблених з урахуванням ваших конкретних потреб. Наші послуги включають в себе:
				</Text>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Спеціалізоване прибирання
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Прибирання кухні: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Глибоке очищення духовок, холодильників та шаф.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Прибирання ванних кімнат:
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Дезінфекція та чищення душових кабін, ванн, раковин і туалетів.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Чистка килимів та оббивки: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Професійне чищення для видалення плям і запахів.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://quantumixfix.com/img/8.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					max-height="380px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Це лише короткий огляд того, що може запропонувати Clean&Co Services. Наша мета - забезпечити вам чисте, здорове та комфортне середовище для життя. Щоб дізнатися більше про наш повний спектр послуг або записатися на прибирання, зв'яжіться з нами вже сьогодні. Наші привітні та компетентні співробітники завжди готові відповісти на будь-які запитання та допомогти вам знайти ідеальне рішення для прибирання вашої оселі.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-secondary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});